import { Controller, ControllerProps, FieldPath, FieldValues, useWatch } from 'react-hook-form';
import { Checkbox } from '../../../components/ui/checkbox';
import { Label } from '../../../components/ui/label';
import { Avatar, AvatarFallback, AvatarImage } from '../../../components/ui/avatar';
import { useMemo, useState } from 'react';
import { Input } from '@/components/ui';
import { useRoles } from '../hooks/use-roles';
import { useMembers } from '../hooks/use-member';
import { Member } from '@/lib/definitions';
import { cn } from '@/lib/utils';

const ensureArray = (value: string | string[] | undefined): string[] => {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
};

const rolePrefix = 'role__';

interface MembersFormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  /** Function to determine if a member is disabled */
  isDisabled?: (id: string) => boolean;

  /** If true, role should be included to the field value */
  includeRole?: boolean;
}

const MembersFormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({ includeRole, isDisabled, ...props }: MembersFormFieldProps<TFieldValues, TName>) => {
  const roles = useRoles('MembersFormField');
  const { members: companyMembers, getMember } = useMembers('MembersFormField');

  const field = useWatch(props) as string[];

  const [selectedRoles, setSelectedRoles] = useState<Set<string>>(() => {
    if (!includeRole) return new Set<string>();

    const roles = new Set<string>();
    field.forEach((value) => {
      if (value.startsWith(rolePrefix)) {
        roles.add(value.slice(rolePrefix.length));
      }
    });

    return roles;
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const toggleSelectedRole = (bool: boolean, ...roleIds: string[]) => {
    setSelectedRoles((prev) => {
      const newSelectedRoles = new Set(prev);
      roleIds.forEach((roleId) => {
        if (bool) {
          newSelectedRoles.add(roleId);
        } else {
          newSelectedRoles.delete(roleId);
        }
      });
      return newSelectedRoles;
    });
  };

  const members = useMemo(() => {
    if (!searchTerm) return companyMembers;

    const q = searchTerm.toLowerCase();
    return companyMembers.filter((member) => member.name.toLowerCase().includes(q));
  }, [companyMembers, searchTerm]);

  const roleMemberMapping = useMemo(() => {
    const map = {} as Record<string, Set<string>>;

    members.forEach((member) => {
      const roles = ensureArray(member.role);
      roles.forEach((role) => {
        if (role in map) {
          map[role].add(member.id);
        } else {
          map[role] = new Set([member.id]);
        }
      });
    });

    return map;
  }, [members]);

  const getRoleKey = (roleId: string) => `${rolePrefix}${roleId}`;

  const isAdmin = (id: string) => {
    const member = getMember(id, false);
    if (!member) return false;
    return (member as Member).role === 'admin';
  };

  return (
    <div className='space-y-4'>
      {/* Search bar */}
      <Input
        placeholder='Search members...'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term when user types
      />

      {/* Roles selection */}
      <div className='flex flex-wrap gap-1'>
        {roles.data.map((role) => (
          <Controller
            key={`role-${role.id}`}
            {...props}
            render={({ field }) => {
              const key = getRoleKey(role.id);
              const checked = selectedRoles.has(role.id) || ensureArray(field.value).includes(key);

              return (
                <Label htmlFor={key} className={cn('font-normal flex items-center gap-2 cursor-pointer hover:bg-secondary/20 px-4 py-1.5 rounded-lg border', checked && 'bg-secondary/30 border-input')}>
                  <Checkbox
                    id={key}
                    className='hidden'
                    checked={checked}
                    onCheckedChange={(checked) => {
                      const fieldValue = ensureArray(field.value);
                      if (includeRole) {
                        // Toggle the role key in the field value
                        field.onChange(
                          checked
                            ? [...fieldValue, key]
                            : fieldValue.filter((value) => value !== key),
                        );
                      } else {
                        // Toggle all members with this role
                        if (role.id in roleMemberMapping) {
                          const selectedRoleMembers = roleMemberMapping[role.id];
                          field.onChange(
                            checked
                              ? [...fieldValue, ...Array.from(selectedRoleMembers)]
                              : fieldValue.filter((memberId) => isDisabled?.(memberId) || !selectedRoleMembers.has(memberId)),
                          );
                        }
                      }

                      // Now, update the selected roles state
                      toggleSelectedRole(checked === true, role.id);
                    }}
                  />
                  <span>{role.name}</span>
                </Label>
              );
            }}
          />
        ))}
      </div>

      {/* Members list */}
      <div className='space-y-px'>
        {members.map((member) => {
          const formId = `member-${member.id}`;

          return (
            <Controller
              key={member.id}
              {...props}
              render={({ field }) => {
                const roles = ensureArray(member.role);
                const isRoleSelected = roles.some((role) => selectedRoles.has(role));
                const checked = ensureArray(field.value).includes(member.id) || isRoleSelected;

                return (
                  <div className={cn('flex items-center gap-4 py-1 px-2 rounded-lg', isDisabled?.(member.id) && 'opacity-50')}>
                    <Label htmlFor={formId}>
                      <Avatar>
                        <AvatarImage src='' alt={member.name} />
                        <AvatarFallback>{member.name[0]}</AvatarFallback>
                      </Avatar>
                    </Label>
                    <Label htmlFor={formId} className='flex flex-col cursor-pointer'>
                      <div>
                        <span className='text-sm leading-4'>{member.name}</span>
                        {isAdmin(member.id) && (
                          <span className='text-xs font-medium text-secondary border-secondary border rounded-md px-2 py-px ml-1'>
                            admin
                          </span>
                        )}
                      </div>
                      <small className='text-xs text-muted-foreground font-normal'>
                        {member.email}
                      </small>
                    </Label>
                    <Checkbox
                      id={formId}
                      aria-readonly={isDisabled?.(member.id)}
                      disabled={isDisabled?.(member.id)}
                      className='ml-auto'
                      checked={checked}
                      onCheckedChange={(checked) => {
                        const fieldValue = ensureArray(field.value);

                        if (checked) {
                          field.onChange([...fieldValue, member.id]);
                        } else if (isRoleSelected) {
                          // If any of the role of the member is selected, remove all roles and add all other members
                          // This is so the whole process look natural for the user
                          const newFieldValue = new Set(fieldValue);

                          // Remove all roles that are selected
                          roles.forEach((role) => {
                            const roleMembers = roleMemberMapping[role];
                            for (const memberId of roleMembers) {
                              if (isDisabled?.(memberId) || memberId === member.id) continue;
                              newFieldValue.add(memberId);
                            }
                            newFieldValue.delete(getRoleKey(role));
                          });

                          newFieldValue.delete(member.id);
                          field.onChange(Array.from(newFieldValue));

                          toggleSelectedRole(false, ...roles);
                        } else {
                          field.onChange(fieldValue.filter((value) => value !== member.id));
                        }
                      }}
                    />
                  </div>
                );
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export { MembersFormField };
