'use client';

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  HeaderGroup,
  Row,
  SortingState,
  TableState,
  Table as TanstackTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Button } from './button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './dropdown';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { createContext } from '@/lib/context';
import { Input } from './input';
import { ArrowDownZAIcon, ArrowUpAZIcon, ChevronsUpDownIcon } from 'lucide-react';

interface DataTableValueProps<TData, TValue = any> {
  table: TanstackTable<TData>;
  columns: ColumnDef<TData, TValue>[];
  rows: Row<TData>[];
  headerGroups: HeaderGroup<TData>[];
  state: Partial<TableState>;
}

const [useDataTable, DataTableProvider] = createContext<DataTableValueProps<any>>('DataTable');

interface DataTableProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  getRowId?: (row: TData) => string;
}

interface DataTableRef<TData = any, TValue = any> extends DataTableValueProps<TData, TValue> {}

const DataTableComponent = <TData, TValue>(
  { columns, data, getRowId, ...props }: DataTableProps<TData, TValue>,
  ref: ForwardedRef<DataTableRef<TData>>,
) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState({});

  const state = useMemo(
    () => ({
      sorting,
      columnFilters,
      rowSelection,
    }),
    [sorting, columnFilters, rowSelection],
  );

  const table = useReactTable({
    data,
    columns,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state,
    autoResetPageIndex: true,
  });

  const rows = table.getRowModel().rows;
  const headerGroups = table.getHeaderGroups();

  useImperativeHandle(
    ref,
    () => ({
      state,
      table,
      columns,
      rows,
      headerGroups,
    }),
    [state, table, columns, rows, headerGroups],
  );

  return (
    <DataTableProvider
      table={table}
      columns={columns}
      rows={rows}
      headerGroups={headerGroups}
      state={state}
    >
      <div {...props} />
    </DataTableProvider>
  );
};

const DataTable = forwardRef(DataTableComponent) as <TData, TValue>(
  props: DataTableProps<TData, TValue> & { ref?: ForwardedRef<DataTableRef<TData>> },
) => JSX.Element;

const DataTableContent = () => {
  const { headerGroups, rows, columns } = useDataTable('DataTableContent');
  return (
    <Table>
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {rows?.length ? (
          rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow className='hover:bg-transparent'>
            <TableCell colSpan={columns.length} className='h-24 text-center'>
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

interface DataTableColumnHeaderProps<TData, TValue> extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
  type?: 'date' | 'text';
}

const DataTableColumnHeader = <TData, TValue>({
  column,
  title,
  className,
  type = 'text',
}: DataTableColumnHeaderProps<TData, TValue>) => {
  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            size='sm'
            className='h-8 data-[state=open]:bg-primary/[0.05] gap-2'
          >
            <span>{title}</span>
            {column.getIsSorted() === 'desc' ? (
              <ArrowDownZAIcon className='h-4 w-4' />
            ) : column.getIsSorted() === 'asc' ? (
              <ArrowUpAZIcon className='h-4 w-4' />
            ) : (
              <ChevronsUpDownIcon className='h-4 w-4' />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          <DropdownMenuItem onClick={() => column.clearSorting()}>
            <ChevronsUpDownIcon className='h-4 w-4 text-muted-foreground/70' />
            Default
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <ArrowUpAZIcon className='h-4 w-4 text-muted-foreground/70' />
            {type === 'date' ? 'Oldest' : 'Asc'}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <ArrowDownZAIcon className='h-4 w-4 text-muted-foreground/70' />
            {type === 'date' ? 'Newest' : 'Desc'}
          </DropdownMenuItem>
          {/* <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
            <EyeNoneIcon className="text-muted-foreground/70" />
            Hide
          </DropdownMenuItem> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

const DataTablePagination = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const { table } = useDataTable('DataTablePagination');
  return (
    <div {...props} className={cn('flex items-center justify-between px-2', props.className)}>
      <div className='flex-1 text-sm text-muted-foreground'>
        {table.getFilteredSelectedRowModel().rows.length} of{' '}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div>
      <div className='flex items-center space-x-6 lg:space-x-8'>
        <div className='flex w-[100px] items-center justify-center text-sm font-medium'>
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>Go to previous page</span>
            <ChevronLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>Go to next page</span>
            <ChevronRightIcon className='h-4 w-4' />
          </Button>
        </div>
      </div>
    </div>
  );
};

interface DataTableFilterProps extends React.HTMLAttributes<HTMLInputElement> {
  columnId: string;
  placeholder: string;
}

const DataTableInputFilter = ({ columnId, ...props }: DataTableFilterProps) => {
  const { table } = useDataTable('DataTableFilter');

  const [value, setValue] = useState<string>(
    (table.getColumn(columnId)?.getFilterValue() as string) ?? '',
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    table.getColumn(columnId)?.setFilterValue(event.target.value);
  };

  return (
    <Input
      {...props}
      value={value}
      onChange={handleChange}
      className={cn('max-w-sm', props.className)}
    />
  );
};

export {
  DataTable,
  DataTableContent,
  DataTableColumnHeader,
  DataTablePagination,
  useDataTable,
  DataTableInputFilter,
};

export type { DataTableRef };
