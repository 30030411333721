import { Outlet, RouteObject } from 'react-router-dom';
import { AppLayout, AppContentLayout, AppHeaderLayout, PlainLayout, PlainLayoutProps } from '@/components/layouts';
import { SuspenseWithPerf } from 'reactfire';
import { UIProvider } from '@/providers/ui';
import { lazyImport } from '@/lib/utils';
import { Spinner } from '@/components/icons';
import { Suspense } from 'react';
import { ErrorPage } from '@/features/misc/error-page';
import { Sidebar } from './components/sidebar';
import { LawyerOnboarding } from './pages/lawyer-onboarding';
import { RequireAuth } from '@/providers/auth';
import { RequireUser } from '@/providers/user';
import { RequireLawyer } from './providers/lawyer';
import { LawyerHeader } from './components/header';

const Protected = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <RequireAuth>
        <RequireUser>
          <RequireLawyer>
            {children}
          </RequireLawyer>
        </RequireUser>
      </RequireAuth>
    </SuspenseWithPerf>
  );
};

const fallback = (
  <div className='h-screen grid place-items-center'>
    <Spinner size={28} />
  </div>
);

const Plain = (props: PlainLayoutProps & { preventRedirect?: boolean }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <RequireAuth>
        <RequireUser isOptional={props.preventRedirect}>
          <RequireLawyer isOptional={props.preventRedirect}>
            <PlainLayout {...props}>
              <Outlet />
            </PlainLayout>
          </RequireLawyer>
        </RequireUser>
      </RequireAuth>
    </SuspenseWithPerf>
  );
};

const App = () => {
  return (
    <Protected>
      <UIProvider>
        <AppLayout sidebar={<Sidebar />}>
          <AppHeaderLayout>
            <LawyerHeader />
          </AppHeaderLayout>
          <AppContentLayout>
            <Suspense fallback={fallback}>
              <Outlet />
            </Suspense>
          </AppContentLayout>
        </AppLayout>
      </UIProvider>
    </Protected>
  );
};

const { Dashboard } = lazyImport(() => import('@/features/lawyer/pages'), 'Dashboard');
const { Calendar } = lazyImport(() => import('@/features/lawyer/pages'), 'Calendar');
const { Profile } = lazyImport(() => import('@/features/lawyer/pages'), 'Profile');
const { Tasks } = lazyImport(() => import('@/features/lawyer/pages'), 'Tasks');

export const lawyerRoutes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Dashboard /> },
      {
        path: '/calendar',
        element: <Calendar />,
      },
      // {
      //   path: '/clients',
      //   element: <Clients />,
      // },
      {
        path: '/tasks',
        element: <Tasks />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: 'onboarding',
    element: <Plain preventRedirect />,
    children: [{ index: true, element: <LawyerOnboarding /> }],
  },
];
