import { VegaFile } from '@/lib/definitions';
import { FileAvatar } from '../..';
import { formatDate } from '@/lib/utils';
import { X } from 'lucide-react';

interface FilePreviewProps {
  file: VegaFile;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const FilePreview = ({ file, onClose, children }: FilePreviewProps) => {
  return (
    <div className='relative border py-3 px-6 rounded-lg cursor-pointer border-stroke/10 group flex justify-between'>
      <div className='flex gap-2 items-start'>
        <span className='flex-shrink-0 mt-1'>
          <FileAvatar file={file} />
        </span>
        <div className='grid'>
          <div className='text-sm font-medium'>{file.name}</div>
          <small className='text-muted-foreground leading-4'>Created on {formatDate(file.createdAt)}</small>
        </div>
      </div>
      <div className='mr-2'>
        {children}
      </div>
      {onClose && (
        <span className='absolute z-50 right-2 top-2 rounded-sm opacity-0 ring-offset-background transition-opacity group-hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground' onClick={onClose}>
          <X className="h-4 w-4" />
        </span>
      )}
    </div>
  );
};
