import React from 'react';
import PropTypes from 'prop-types';

import { ArrowLeftIcon, ArrowRightIcon } from '@radix-ui/react-icons';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  if (totalPages === 0) return null;
  return (
    <div className='flex items-center justify-end mt-4 space-x-4'>
      {currentPage > 1 && (
        <div
          onClick={() => {
            onPageChange(currentPage - 1);
          }}
          className='cursor-pointer text-gray-600 hover:text-gray-800 transition duration-300'
        >
          <ArrowLeftIcon width={16} height={16} />
        </div>
      )}
      <span className='text-sm text-gray-700'>
        {currentPage} of {totalPages}
      </span>
      {currentPage < totalPages && (
        <div
          onClick={() => {
            onPageChange(Math.min(currentPage + 1, totalPages));
          }}
          className='cursor-pointer text-gray-600 hover:text-gray-800 transition duration-300'
        >
          <ArrowRightIcon width={16} height={16} />
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
