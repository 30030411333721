import { Permission } from '@/data/permission';

export const CompanyRoutes = {
  Agreements: '/agreements',
  Approval: '/approval',
  Tasks: '/tasks',
  Roles: '/roles',
  Members: '/members',
  CounterParties: '/counterparties',
  ClauseLibrary: '/clause-library',
  Files: '/files',
  Advice: '/advice',
  Experts: '/experts',
  Custom: '/custom',
  Tickets: '/tickets',
  Account: '/account',
} as const;

export type CompanyRouteKeys = keyof typeof CompanyRoutes;

export const CompanyRoutePermission = {
  Agreements: ['admin.agreement:view'],
  Approval: ['admin.approval:view'],
  Tasks: ['admin.task:view'],
  Roles: ['admin.role:view'],
  Members: ['admin.team:view'],
  CounterParties: ['admin.counterparty:view'],
  ClauseLibrary: ['admin.clause:view'],
  Files: ['admin.file:view'],
  Advice: ['admin.legal:view'],
  Experts: ['admin.legal:view'],
  Custom: [],
  Tickets: ['admin.ticket:view'],
  Account: ['admin.account:view'],
} as Record<CompanyRouteKeys, Permission[]>;
