
import { useDatabase, useDatabaseListData } from 'reactfire';
import { ref } from 'firebase/database';
import { useState, useMemo } from 'react';
import { Input, Modal, Accordion, Button, ChkBox } from '@/components/ui';
import { Clause } from '@/lib/definitions';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useCompany } from '../../providers/company';

type ClauseSelectionModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSelectParagraphs: (
    selected: Array<{ clause: Clause; paragraphIndex: number; paragraphText: string }>
  ) => void;
};

export const ClauseSelectionModal = ({
  open,
  onOpenChange,
  onSelectParagraphs,
}: ClauseSelectionModalProps) => {
  const { activeCompany: company } = useCompany('ClauseSelectionModal');
  const db = useDatabase();
  const clauseRef = ref(db, `clause-library/${company?.id}`);

  const { status, data: clauses } = useDatabaseListData<Clause>(clauseRef);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedParagraphs, setSelectedParagraphs] = useState<
    Array<{ clause: Clause; paragraphIndex: number; paragraphText: string }>
  >([]);

  const filteredClauses = useMemo(
    () =>
      clauses?.filter((clause) =>
        clause.title.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [clauses, searchQuery]
  );

  const handleCheckboxChange = (
    clause: Clause,
    paragraphIndex: number,
    isChecked: boolean
  ) => {
    const paragraphText = clause.paragraphs[paragraphIndex];
    const selection = { clause, paragraphIndex, paragraphText };

    if (isChecked) {
      setSelectedParagraphs((prev) => [...prev, selection]);
    } else {
      setSelectedParagraphs((prev) =>
        prev.filter(
          (p) =>
            !(p.clause.id === clause.id && p.paragraphIndex === paragraphIndex)
        )
      );
    }
  };

  const handleSelect = () => {
    onSelectParagraphs(selectedParagraphs);
    onOpenChange(false);
  };

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content className="max-w-3xl">
        <Modal.Header>
          <Modal.Title>Select Clauses and Paragraphs</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => e.preventDefault()}>
          <Modal.Body className="space-y-4">
            {/* Search Input */}
            <Input
              placeholder="Search clauses by title"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            {status === 'loading' ? (
              <p>Loading...</p>
            ) : status === 'error' ? (
              <p>Failed to load clauses.</p>
            ) : (
              <Accordion.Root type="multiple" className="space-y-4">
                {filteredClauses?.map((clause) => (
                  <Accordion.Item
                    key={clause.id}
                    value={clause.id}
                    className="bg-gray-100 rounded-md shadow"
                  >
                    <Accordion.Header>
                      <Accordion.Trigger className="flex justify-between items-center w-full p-3 text-left font-medium">
                        <p className="font-medium text-sm">{clause.title}</p>
                        <ChevronDownIcon
                          className={`transition-transform ${
                            selectedParagraphs.some(
                              (p) => p.clause.id === clause.id
                            )
                              ? 'rotate-180'
                              : ''
                          }`}
                        />
                      </Accordion.Trigger>
                    </Accordion.Header>
                    <Accordion.Content className="p-4 bg-gray-50 space-y-2">
                      {clause.paragraphs.map((paragraph, index) => (
                        <div key={index} className="flex items-start">
                          <ChkBox.Root
                            id={`${clause.id}-para-${index}`}
                            checked={selectedParagraphs.some(
                              (p) =>
                                p.clause.id === clause.id &&
                                p.paragraphIndex === index
                            )}
                            onCheckedChange={(state) =>
                              handleCheckboxChange(
                                clause,
                                index,
                                state === true
                              )
                            }                        
                          >
                            <ChkBox.Indicator />
                          </ChkBox.Root>
                          <label
                            htmlFor={`${clause.id}-para-${index}`}
                            className="ml-2 text-sm text-gray-700"
                          >
                            {paragraph || 'No paragraph available'}
                          </label>
                        </div>
                      ))}
                      {clause.guidanceNote && (
                        <p className="text-sm text-gray-600">
                          <strong>Guidance Note:</strong> {clause.guidanceNote}
                        </p>
                      )}
                      <p className="text-xs text-gray-400">
                        Created by: {clause.createdBy.name} on{' '}
                        {new Date(clause.createdOn).toLocaleDateString()}
                      </p>
                      {clause.updatedOn && (
                        <p className="text-xs text-gray-400">
                          Updated on: {new Date(clause.updatedOn).toLocaleDateString()}
                        </p>
                      )}
                    </Accordion.Content>
                  </Accordion.Item>
                ))}
                {filteredClauses?.length === 0 && <p>No clauses found.</p>}
              </Accordion.Root>
            )}
          </Modal.Body>
          <Modal.Footer className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Close
            </Button>
            <Button
              onClick={handleSelect}
              disabled={selectedParagraphs.length === 0}
            >
              Select
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};