import { VegaFile } from '@/lib/definitions';
import { useControllableState, usePromise } from '@/lib/hooks';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SelectFileModal } from './select-file-modal';
import { Button, Input, Modal } from '../../../../../components/ui';
import { useUser } from '@/providers/user';
import { Controller, useForm } from 'react-hook-form';
import { CloudUploadIcon } from 'lucide-react';
import { MembersFormField } from '../../members-form-field';
import { useUpload } from '@/features/company/hooks/use-upload';
import { NoReactFire_useFile, useFiles } from '@/features/company/hooks/use-files';
import { useCompany } from '@/features/company/providers/company';
// import { Spinner } from '@/components/icons';

interface UploadFileModalProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  parentId?: string | '~';
  files?: File[];
  accept?: string;
  multiple?: boolean;
}

export const UploadFileModal = (props: UploadFileModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const fileSelectorRef = useRef<FileSelectorRef>(null);

  const [files, setFiles] = useState<File[]>(props.files?? []);
  const [step, setStep] = useState<'idle' | 'upload-file'>('idle');

  useEffect(() => {
    if (open) {
      if (files.length) {
        setStep('upload-file');
      } else {
        fileSelectorRef.current?.open();
      }
    }
  }, [open]);

  const closeModal = useCallback(() => {
    setFiles([]);
    setStep('idle');
    onOpenChange(false);
  }, []);

  return (
    <>
      <FileSelector
        ref={fileSelectorRef}
        multiple={props.multiple}
        accept={props.accept ?? DEFAULT_ACCEPT}
        onFilesSelected={(files) => {
          setFiles(files);
          setStep('upload-file');
        }}
        onClose={closeModal}
      />
      {props.children && <Slot onClick={() => onOpenChange(true)}>{props.children}</Slot>}
      {step === 'upload-file' && (
        <UploadFile files={files} folderId={props.parentId ?? '~'} onClose={closeModal} />
      )}
    </>
  );
};

interface UploadFileProps {
  files: File[];
  folderId: string;
  onClose: () => void;
}

const UploadFile = ({ files, folderId, onClose }: UploadFileProps) => {
  const [folder, setFolder] = useState<VegaFile | null>(null);
  const [step, setStep] = useState<'upload-form' | 'select-folder'>('upload-form');

  const { status } = NoReactFire_useFile('UploadFile', folderId ?? '~', setFolder, { once: true });

  if (status === 'loading') {
    // return <LoadingOverlay />;
    // TODO: white background remains static. Overlay alone changes
    return null
  }

  return (
    <>
      <UploadFileForm
        open={step === 'upload-form'}
        onOpenChange={(open) => {
          if (!open) onClose();
        }}
        selectedFiles={files}
        folder={folder}
        onChooseFolder={() => setStep('select-folder')}
      />
      <SelectFileModal
        mode='folder'
        open={step === 'select-folder'}
        onOpenChange={(open) => {
          if (!open) setStep('upload-form');
        }}
        onFileChange={setFolder}
        initialParent={folderId}
        addFolder
      />
    </>
  );
};

type UploadFileFormDTO = {
  users: string[];
  selectedFiles: { name: string; id: number }[];
};

interface UploadFileFormProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children?: React.ReactNode;
  selectedFiles: File[];
  folder: VegaFile | null;
  onChooseFolder: () => void;
}

const UploadFileForm = ({
  children,
  selectedFiles,
  folder,
  onChooseFolder,
  ...props
}: UploadFileFormProps) => {
  const { user } = useUser('UploadFileForm');
  const { activeCompany: company } = useCompany('UploadFileForm');

  const { data: files } = useFiles('UploadFileForm', folder?.id);
  const { addUpload } = useUpload();

  const form = useForm<UploadFileFormDTO>({
    defaultValues: {
      selectedFiles: selectedFiles.map((file, index) => ({
        name: file.name.split('.')[0],
        id: index,
      })),
      users: [user?.uid],
    },
  });

  const generateFileName = useCallback(
    (fileName: string): string => {
      let version = 1;
      let newFileName = fileName;

      const existingFileNamesSet = new Set(
        // Strip extension and convert to lowercase
        files.map((file) => file.name.replace(/\.[^/.]+$/, '').toLowerCase()),
      );

      // Check if newFileName exists and append version if needed
      while (existingFileNamesSet.has(newFileName.toLowerCase())) {
        newFileName = `${fileName}(${version})`;
        version += 1;
      }

      return newFileName;
    },
    [files],
  );

  const [submit, isSubmitting] = usePromise(
    form.handleSubmit(async (data) => {
      if (!company || !user) {
        throw new Error('Invalid company or user');
      }

      selectedFiles.forEach((file, index) => {
        const [fileName, extension] = file.name.split('.');
        let name = data.selectedFiles[index]?.name || fileName;
        name = generateFileName(name);

        addUpload({
          file,
          accessBy: data.users,
          name: name + '.' + extension,
          parentId: folder?.id ?? '~',
        });
      });

      props.onOpenChange(false);
    }),
  );

  const isSelf = (id: string) => id === user.uid;

  return (
    <Modal.Root {...props}>
      {children && <Modal.Trigger asChild>{children}</Modal.Trigger>}
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <CloudUploadIcon width={24} height={24} className='text-primary' />
            <span>Upload file</span>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={submit}>
          <Modal.Body className='space-y-8'>
            <Controller
              name='selectedFiles'
              control={form.control}
              render={({ field }) => (
                <div className='flex flex-col gap-4'>
                  {field.value.length === 1 ? (
                    <Input label='Name' {...form.register('selectedFiles.0.name')} />
                  ) : (
                    selectedFiles.map((file, index) => (
                      <Input
                        key={index}
                        label={`Name for ${file.name}`}
                        {...form.register(`selectedFiles.${index}.name`)}
                      />
                    ))
                  )}
                  <button
                    type='button'
                    className='px-6 py-5 flex items-center justify-between h-10 w-full rounded-xl border border-input bg-transparent text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1'
                    onClick={() => onChooseFolder()}
                  >
                    {folder?.name ?? 'Root'}
                  </button>
                </div>
              )}
            />
            <div>
              <div className='text-sm font-normal text-muted-foreground sticky top-[61px] bg-background py-4 z-10'>
                Choose who can access this file
              </div>
              <MembersFormField
                includeRole
                name='users'
                control={form.control}
                isDisabled={isSelf}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Close asChild>
              <Button size='lg' variant='outline'>
                Cancel
              </Button>
            </Modal.Close>
            <Button size='lg' type='submit' loading={isSubmitting}>
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

interface FileSelectorProps {
  multiple?: boolean;
  accept?: string;
  onFilesSelected: (files: File[]) => void;
  onClose: () => void;
}

interface FileSelectorRef {
  open: () => void;
}

const DEFAULT_ACCEPT = '.docx, .pdf, image/*, .xls, .xlsx, .ppt, .pptx';

const FileSelector = forwardRef<FileSelectorRef, FileSelectorProps>(
  ({ multiple, accept, onFilesSelected, onClose }, ref) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const isSelectorOpen = useRef(false);
    const [inputKey, setInputKey] = useState(0);

    const resetInput = useCallback(() => {
      setInputKey((prev) => prev + 1);
      isSelectorOpen.current = false;
    }, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        resetInput(); // Reset if no files are selected
        return;
      }
      const files = multiple ? Array.from(e.target.files) : [e.target.files[0]];
      onFilesSelected(files);
      resetInput();
    };

    const open = useCallback(() => {
      if (fileInputRef.current && !isSelectorOpen.current) {
        fileInputRef.current.click();
        isSelectorOpen.current = true;
      }
    }, []);

    useEffect(() => {
      const fileInput = fileInputRef.current;
      if (!fileInput) return;

      const handleCancel = () => {
        resetInput();
        onClose?.();
      };

      // Attach listener for "cancel" event
      fileInput.addEventListener('cancel', handleCancel);

      return () => {
        fileInput.removeEventListener('cancel', handleCancel);
      };
    }, [resetInput]);

    useImperativeHandle(ref, () => ({
      open,
    }));

    return (
      <input
        key={inputKey}
        hidden
        type='file'
        ref={fileInputRef}
        multiple={multiple}
        accept={accept ?? DEFAULT_ACCEPT}
        onChange={handleFileChange}
      />
    );
  },
);

FileSelector.displayName = 'FileSelector';

// const LoadingOverlay = () => (
//   <Modal.Root open>
//     <Modal.Content>
//       <Modal.Body>
//         <div className='h-[calc(100vh-8rem)] flex items-center justify-center'>
//           <Spinner />
//         </div>
//       </Modal.Body>
//     </Modal.Content>
//   </Modal.Root>
// );
