import { useCallback, useState } from 'react';

export const useModal = <T extends string, Data = any>(initialModal?: T, data?: Data) => {
  const [modal, setModal] = useState<{
    name: T;
    data?: any;
  } | null>(initialModal ? { name: initialModal, data } : null);

  const openModal = (name: T, data?: any) => setModal({ name, data });

  const closeModal = () => setModal(null);

  const modalProps = useCallback((name: T, key = 'data') => ({
    open: modal?.name === name,
    onOpenChange: (open: boolean) => {
      if (!open) {
        closeModal();
      }
    },
    [key]: modal?.data,
  }), [modal]);

  return {
    modal,
    openModal,
    closeModal,
    modalProps,
  };
};
