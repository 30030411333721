import { useCallback, useReducer } from 'react';

type Options = {
  isInitiallyLoading?: boolean;
  onError?: (error: Error) => void;
};

type Status = 'idle' | 'pending' | 'success' | 'error';

type State<T, E> = {
  status: Status;
  data: T | null;
  error: E | null;
};

type Action<T, E> =
  | { type: 'idle' }
  | { type: 'pending' }
  | { type: 'success'; result: T }
  | { type: 'error'; error: E };

type UsePromiseHook<T, Args extends any[], E> = [
  (...args: Args) => Promise<T | undefined>,
  boolean,
  T | null,
  E | null,
  Status,
  () => void,
];

const initialState = <T, E>(loading: boolean): State<T, E> => ({
  status: loading ? 'pending' : 'idle',
  data: null,
  error: null,
});

const reducer = <T, E>(state: State<T, E>, action: Action<T, E>): State<T, E> => {
  switch (action.type) {
  case 'idle':
    return { ...state, status: 'idle', data: null, error: null };
  case 'pending':
    return { ...state, status: 'pending', error: null };
  case 'success':
    return { ...state, status: 'success', data: action.result };
  case 'error':
    return { ...state, status: 'error', error: action.error };
  default:
    return state;
  }
};

type PromiseReducer<T, E> = (state: State<T, E>, action: Action<T, E>) => State<T, E>;

export const usePromise = <T = any, Args extends any[] = any, E = Error>(
  fn: (...args: Args) => Promise<T>,
  options?: Options,
): UsePromiseHook<T, Args, E> => {
  const [state, dispatch] = useReducer<PromiseReducer<T, E>>(
    reducer,
    initialState<T, E>(options?.isInitiallyLoading ?? false)
  );

  const run = useCallback(async (...args: Args) => {
    try {
      dispatch({ type: 'pending' });
      const response = await fn(...args);
      dispatch({ type: 'success', result: response });
      return response;
    } catch (error) {
      dispatch({ type: 'error', error: error as E });
      options?.onError?.(error as Error);
    }
  }, [fn]);

  const reset = useCallback(() => {
    dispatch({ type: 'idle' });
  }, []);

  const loading = state.status === 'pending';
  return [run, loading, state.data, state.error, state.status, reset];
};
