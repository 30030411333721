import { CircularProgressBar } from '@/components/ui/circular-progress';
import { Toast } from '@/components/ui/toast';
import { useUpload } from '@/features/company/hooks/use-upload';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Check } from 'lucide-react';
import { useEffect, useState } from 'react';
import { uploadFile } from '../actions/files';
import { toast } from 'react-toastify';

export const Uploader = () => {
  const [open, onOpenChange] = useState(false);
  const { uploads, currentUploadId: nextId, getUpload, startUpload, updateUpload, completeUpload, clearCompletedUploads } = useUpload();

  useEffect(() => {
    if (nextId == null) {
      return;
    }

    const upload = getUpload(nextId);
    if (upload == null) {
      return;
    }

    startUpload(upload.id);
    uploadFile({
      file: upload.file,
      name: upload.name,
      parentId: upload.parentId,
      accessBy: upload.accessBy,
      onProgress: (progress) => {
        updateUpload(upload.id, progress);
      },
    }).then(() => {
      completeUpload(upload.id);
    }).catch((error) => {
      console.error('File upload failed:', error);
      toast.error('File upload failed');
      updateUpload(upload.id, 0); 
    });
  }, [nextId]);

  useEffect(() => {
    if (uploads.length > 0) {
      onOpenChange(true);
    }
  }, [uploads]);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      clearCompletedUploads();
    }
    onOpenChange(open);
  };

  return (
    <Toast duration={nextId == null ? 30000 : Infinity} open={open} onOpenChange={handleOpenChange} onPointerMove={(e) => e.preventDefault()}>
      <div className="space-y-2">
        <h4 className='text-base font-semibold'>Uploads</h4>
        <div className="flex flex-col gap-px text-sm">
          {uploads.map((upload) => (
            <div key={upload.id} className="group grid grid-cols-[auto,1fr] gap-2 items-center">
              <span>
                {upload.status === 'completed' ? (
                  <Check className='text-success size-4' />
                ) : (
                  <CircularProgressBar
                    max={100}
                    min={0}
                    value={upload.progress}
                    className='size-4'
                  />
                )}
              </span>
              <div className='text-ellipsis whitespace-nowrap overflow-hidden w-full'>{upload.name}</div>
            </div>
          ))}
        </div>
      </div>
      <button
        className='absolute right-1 top-1 rounded-md p-1 text-foreground/50 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-1 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600'
        onClick={() => onOpenChange(false)}
      >
        <Cross2Icon className='w-4 h-4' />
      </button>
    </Toast>
  );
};
