import { canUserOnlyTraverse } from '@/features/company/actions/files';
import { VegaFile } from '@/lib/definitions';
import { cn } from '@/lib/utils';
import { useUser } from '@/providers/user';
import { FileIcon, FileImageIcon, FileLock2, FolderIcon, FolderLock, LucideProps } from 'lucide-react';

interface FileAvatarProps extends Omit<LucideProps, 'ref'> {
  file: VegaFile;
}

export const FileAvatar = ({ file, ...props }: FileAvatarProps) => {
  const { user } = useUser('FileAvatar');
  const className = cn('w-4 h-4', props.className);

  if (file.type === 'folder') {
    if (canUserOnlyTraverse(file, user.uid)) {
      return <FileLock2 className={className} />;
    }

    if (file.status === 'completed') {
      return <FolderLock className={className} />;
    }

    return <FolderIcon className={className} />;
  }

  const extension = file.name.split('.').pop()?.toLowerCase();

  if (extension && ['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
    return <FileImageIcon className={className} />;
  }

  if (file.status === 'completed') {
    return <FileLock2 className={className} />;
  }

  return <FileIcon className={className} />;
};
