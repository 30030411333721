import { SidebarProvider } from '../ui/sidebar';

const AppLayout = ({
  children,
  sidebar,
}: {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}) => {
  return (
    // <main className='md:ml-60 min-h-screen bg-muted'>
    //   <SidebarWrapper sidebar={sidebar}>
    //     {children}
    //   </SidebarWrapper>
    // </main>
    <SidebarProvider>
      {sidebar}
      <main className='relative flex h-svh overflow-x-auto flex-1 flex-col bg-muted peer-data-[variant=inset]:min-h-[calc(100svh-theme(spacing.4))] md:peer-data-[variant=inset]:m-2 md:peer-data-[state=collapsed]:peer-data-[variant=inset]:ml-2 md:peer-data-[variant=inset]:ml-0 md:peer-data-[variant=inset]:rounded-xl md:peer-data-[variant=inset]:shadow'>
        {children}
      </main>
    </SidebarProvider>
  );
};

const AppHeaderLayout = ({
  children,
}: {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
}) => {
  return (
    <header className='sticky top-0 h-[var(--header-height)] shrink-0 flex items-center border-y border-ring z-[1] bg-background'>
      <div className='w-full max-w-7xl px-4'>
        {children}
      </div>
    </header>
  );
};

const AppContentLayout = ({ children }: { children: React.ReactNode }) => {
  return <div className='flex-1 w-full max-w-7xl py-8 px-4 md:px-8'>{children}</div>;
};

export {
  AppLayout,
  AppHeaderLayout,
  AppContentLayout,
};