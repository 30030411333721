import { Button, Input, Modal } from '@/components/ui';
import { VegaFile } from '@/lib/definitions';
import { useControllableState, useModal } from '@/lib/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FileTextIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { z } from 'zod';
import { SelectFileModal } from './select-file-modal';

type SaveAsModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  file: VegaFile;
  onSave: (newName: string, folder?: VegaFile | null) => Promise<void>;
};

const schema = z.object({
  name: z.string().min(1, 'Name is required'),
});

type DTO = z.infer<typeof schema>;

export const SaveAsModal = (props: SaveAsModalProps) => {
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const form = useForm<DTO>({
    resolver: zodResolver(schema),
    defaultValues: { name: '' },
  });

  const [folder, setFolder] = useState<VegaFile | null>(null);
  const { openModal, closeModal: closeFolderModal, modal } = useModal<'select'>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (data: DTO) => {
    setIsLoading(true);
    try {
      await props.onSave(data.name, folder);
      onOpenChange(false);
    } catch (error) {
      console.error('Save failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      form.reset();
      setFolder(null);
    }
    onOpenChange(open);
  };

  return (
    <>
      <Modal.Root open={open} onOpenChange={handleOpenChange}>
        {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}

        <Modal.Content>
          <Modal.Header>
            <div className='flex gap-4 items-center'>
              <FileTextIcon className='w-7 h-7 text-primary' />
              <Modal.Title>Save As</Modal.Title>
            </div>
          </Modal.Header>
          <form onSubmit={form.handleSubmit(handleSave)}>
            <Modal.Body className='space-y-8'>
              <Modal.Description>
                You have chosen to save {props?.file?.name} as a new document. Please enter a new
                name:
              </Modal.Description>
              <div className='flex flex-col gap-4'>
                <Input label='Name' {...form.register('name')} />
                <div className='flex flex-col gap-2'>
                  <label htmlFor='folder' className='text-sm font-normal text-muted-foreground'>
                    Choose folder
                  </label>
                  <button
                    type='button'
                    className='px-6 py-5 flex items-center justify-between h-10 w-full rounded-xl border border-input bg-transparent text-sm shadow-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1'
                    onClick={() => openModal('select')}
                  >
                    {folder?.name ?? 'No Folder'}
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.Close asChild>
                <Button size='lg' variant='outline'>
                  Cancel
                </Button>
              </Modal.Close>
              <Button size='lg' loading={isLoading} type='submit'>
                {isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Content>
      </Modal.Root>

      <SelectFileModal
        mode='folder'
        open={modal?.name === 'select'}
        onOpenChange={(open) => {
          if (!open) closeFolderModal();
        }}
        onFileChange={setFolder}
        addFolder={true}
      />
    </>
  );
};
