import { Folder, Member, VegaFile } from '@/lib/definitions';
import { useUser } from '@/providers/user';
import { and, doc, DocumentData, getDoc, onSnapshot, or, orderBy, Query, query, where } from 'firebase/firestore';
import { ObservableStatus, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import { getFileCollection } from '../actions/files';
import { useCompany } from '../providers/company';
import { useMember } from './use-member';
import { useEffect, useMemo, useState } from 'react';
import { ensureArray } from '@/lib/utils';

const rolePrefix = 'role__';

export const useFiles = (consumerName: string, parentId = '~') => {
  const { user } = useUser(consumerName);
  const { activeCompany: company } = useCompany(consumerName);
  const member = useMember(consumerName, user.uid) as Member;

  const filesQuery = useMemo(() => {
    const roleAccesses = ensureArray(member.role).map((role) => `${rolePrefix}${role}`);
    const hasAccess = where('accessBy', 'array-contains-any', [member?.id, ...roleAccesses]);
    // const isOwner = where('createdBy', '==', user.uid);
    const isPublic = or(where('accessBy', '==', []), where('accessBy', '==', null));
    const canTraverse = where('traverseBy', 'array-contains-any', [member?.id, ...roleAccesses]);

    return query(
      getFileCollection(company.id),
      and(
        where('parent', '==', parentId),
        where('deletedAt', '==', null),
        // or(hasAccess, isOwner, isPublic, canTraverse),
        or(hasAccess, isPublic, canTraverse),
      ),
      orderBy('type', 'asc'),
      orderBy('name', 'asc'),
      orderBy('createdAt', 'desc'),
    ) as Query<VegaFile, DocumentData>;
  }, [company.id, parentId, user.uid, member]);

  return useFirestoreCollectionData<VegaFile>(filesQuery, { idField: 'id' });
};

export const useFile = (consumerName: string, fileId: string) => {
  const { activeCompany: company } = useCompany(consumerName);
  const fileReference = doc(getFileCollection(company.id), fileId);

  return useFirestoreDocData<VegaFile>(fileReference as any, { idField: 'id' });
};

const root: Folder = {
  id: '~',
  name: 'Root',
  type: 'folder',
  parent: '~', // Just to make it explicit
  createdBy: '',
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  accessBy: [],
  traverseBy: [],
  mode: 'generated',
  childCount: 0,
  companyId: '',
  updatedBy: '',
  status: '',
  category: '',
};

export const NoReactFire_useFile = (
  consumerName: string,
  fileId = '~',
  onChange?: (data: VegaFile) => void,
  options?: { once?: boolean } // Add an options object with a `once` key
) => {
  const { activeCompany: company } = useCompany(consumerName);

  const [data, setData] = useState<VegaFile | null>(null);
  const [status, setStatus] = useState<ObservableStatus<object>['status']>('loading');
  const [error, setError] = useState<ObservableStatus<object>['error']>();

  useEffect(() => {
    if (fileId === '~') {
      setData(root);
      setStatus('success');
      return;
    }

    const fileReference = doc(getFileCollection(company.id), fileId);

    if (options?.once) {
      // Fetch the document only once
      getDoc(fileReference)
        .then((snapshot) => {
          const val = snapshot.data();
          setData(val as VegaFile);
          onChange?.(val as VegaFile);
          setStatus('success');
        })
        .catch((err) => {
          console.error(err);
          setError(err);
          setStatus('error');
        });
    } else {
      // Listen for real-time updates
      const unsubscribe = onSnapshot(
        fileReference,
        (snapshot) => {
          const val = snapshot.data();
          setData(val as VegaFile);
          onChange?.(val as VegaFile);
          setStatus('success');
        },
        (err) => {
          console.error(err);
          setError(err);
          setStatus('error');
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [company.id, fileId, onChange, options?.once]);

  return { data, status, error };
};


export const useFileAccess = (consumerName: string, fileId: string) => {
  const { user } = useUser(consumerName);
  const member = useMember(consumerName, user.uid) as Member;
  const { data: file } = useFile(consumerName, fileId);

  const isTraverseOnly = useMemo(() => {
    if (!file || file.type !== 'folder' || !file.accessBy?.length) return false;

    const roles = ensureArray(member.role);
    const roleAccesses = roles.map((role) => `${rolePrefix}${role}`);
    const canOnlyTraverseThrough = !file.accessBy?.includes(member.id) && !file.accessBy?.some((role) => roleAccesses.includes(role));
    return canOnlyTraverseThrough;
  }, [file, member]);

  return { isTraverseOnly };
};
