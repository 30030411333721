import { Breadcrumb } from '@/components/ui/breadcrumb';
import { getFileCollection } from '@/features/company/actions/files';
import { useCompany } from '@/features/company/providers/company';
import { VegaFile } from '@/lib/definitions';
import { doc, getDoc } from 'firebase/firestore';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const FileBreadcrumbAction = ({ file }: { file: VegaFile }) => {
  return (
    <Link to={`/files/${file.id}`}>
      {file.name}
    </Link>
  );
};

type FileBreadcrumbProps = {
  fileId: string;
  children?: React.ReactNode;
  component?: React.ComponentType<{ file: VegaFile }>;
};

export const FileBreadcrumb = ({ fileId, children, component: Component = FileBreadcrumbAction }: FileBreadcrumbProps) => {
  // Used to maintain the order of the breadcrumbs
  const [descendants, setDescendants] = useState<VegaFile[]>([]);

  const { activeCompany: company } = useCompany('FileBreadcrumb');

  useEffect(() => {
    const fetchDescendants = async (parentId: string) => {
      const descendants: VegaFile[] = [];

      while (parentId !== '~') {
        const reference = doc(getFileCollection(company.id), parentId);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists) {
          return descendants.reverse();
        }
        const file = snapshot.data() as VegaFile;
        descendants.push(file);
        parentId = file.parent;
      }

      return descendants.reverse();
    };

    fetchDescendants(fileId).then(setDescendants);
  }, [fileId]);

  return (
    <Breadcrumb.Root>
      <Breadcrumb.List>
        {children}
        {descendants.map((file) => (
          <Fragment key={file.id}>
            <Breadcrumb.Separator />
            <Breadcrumb.Item>
              <Breadcrumb.Link asChild>
                <Component file={file} />
              </Breadcrumb.Link>
            </Breadcrumb.Item>
          </Fragment>
        ))}
      </Breadcrumb.List>
    </Breadcrumb.Root>
  );
};