import { Outlet, RouteObject } from 'react-router-dom';
import { AppLayout, AppContentLayout, AppHeaderLayout, PlainLayout, PlainLayoutProps } from '@/components/layouts';
import { SuspenseWithPerf } from 'reactfire';
import { RequireUser } from '@/providers/user';
import { UIProvider } from '@/providers/ui';
import { lazyImport } from '@/lib/utils';
import { Spinner } from '@/components/icons';
import { Suspense } from 'react';
import { ErrorPage } from '@/features/misc/error-page';
import { Sidebar } from './components';
import { VendorOnboarding } from './pages/vendor-onboarding';
import { VendorHeader } from './components/vendor-header';
import { RequireVendor } from './providers/vendor';

const Protected = ({ children }: { children: React.ReactNode }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <RequireUser>
        <RequireVendor>
          {children}
        </RequireVendor>
      </RequireUser>
    </SuspenseWithPerf>
  );
};

const fallback = (
  <div className='h-screen grid place-items-center'>
    <Spinner size={28} />
  </div>
);

const Plain = (props: PlainLayoutProps & { preventRedirect?: boolean }) => {
  return (
    <SuspenseWithPerf fallback={<h1>Loading...</h1>} traceId='vega-user-wait'>
      <RequireUser>
        <RequireVendor isOptional={props.preventRedirect}>
          <PlainLayout {...props}>
            <Outlet />
          </PlainLayout>
        </RequireVendor>
      </RequireUser>
    </SuspenseWithPerf>
  );
};

const App = () => {
  return (
    <Protected>
      <UIProvider>
        <AppLayout sidebar={<Sidebar />}>
          <AppHeaderLayout>
            <VendorHeader />
          </AppHeaderLayout>
          <AppContentLayout>
            <Suspense fallback={fallback}>
              <Outlet />
            </Suspense>
          </AppContentLayout>
        </AppLayout>
      </UIProvider>
    </Protected>
  );
};

const { Dashboard } = lazyImport(() => import('@/features/vendor/pages'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/vendor/pages'), 'Profile');

export const vendorRoutes: RouteObject[] = [
  {
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Dashboard /> },

      {
        path: '/profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: 'onboarding',
    element: <Plain preventRedirect />,
    children: [{ index: true, element: <VendorOnboarding /> }],
  },
];
