import { Button, Input, Modal } from '@/components/ui';
import { useForm } from 'react-hook-form';
import React from 'react';
import { useControllableState } from '@/lib/hooks/state';
import { useUser } from '@/providers/user';
import { usePromise } from '@/lib/hooks';
import { FolderPlus } from 'lucide-react';
import { MembersFormField } from '../../members-form-field';
import { createFolder } from '@/features/company/actions/files';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form } from '@/components/ui/form';

type NewFolderModalProps = {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  children?: React.ReactNode;
  parentId?: string;
};

const schema = z.object({
  name: z.string().min(1, 'Folder name is required'),
  users: z.array(z.string()),
});

type DTO = z.infer<typeof schema>;

export const NewFolderModal = (props: NewFolderModalProps) => {
  const { user } = useUser('NewFolderModal');
  const [open, onOpenChange] = useControllableState({
    prop: props.open,
    onChange: props.onOpenChange,
    defaultProp: false,
  });

  const form = useForm<DTO>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: '',
      users: [user?.uid],
    }
  });

  const [submit, isSubmitting] = usePromise(
    form.handleSubmit(async (data) => {
      await createFolder(data.name, props.parentId ?? '~', data.users ?? []);
      form.reset();
      onOpenChange(false);
    }),
  );

  const isSelf = (id: string) => id === user.uid;

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      {props.children && <Modal.Trigger asChild>{props.children}</Modal.Trigger>}
      <Modal.Content>
        <Modal.Header>
          <Modal.Title className='flex gap-4 items-center'>
            <FolderPlus width={24} height={24} className='text-primary' />
            <span>New folder</span>
          </Modal.Title>
        </Modal.Header>
        <Form.Root {...form}>
          <form onSubmit={submit}>
            <Modal.Body className='space-y-6'>
              <Form.Field
                control={form.control}
                name="name"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>Folder name</Form.Label>
                    <Form.Control>
                      <Input placeholder="Enter folder name" {...field} />
                    </Form.Control>
                    <Form.Message />
                  </Form.Item>
                )}
              />
              <div className='space-y-1'>
                <div className='text-sm font-normal text-muted-foreground'>
                  Choose who can access this folder
                </div>
                <MembersFormField name="users" control={form.control} isDisabled={isSelf} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.Close asChild>
                <Button size='lg' variant='outline'>
                  Cancel
                </Button>
              </Modal.Close>
              <Button size='lg' type='submit' loading={isSubmitting}>
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Form.Root>
      </Modal.Content>
    </Modal.Root>
  );
};
