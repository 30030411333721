import { useActivity } from '@/providers/activity';
import { AlertDialog } from './ui/alert-dialog';
import { AlertDialogProps } from '@radix-ui/react-alert-dialog';
import { formatDistanceToNowStrict, addMilliseconds } from 'date-fns';

interface InactivityViewProps extends AlertDialogProps {
  timeout: number;
  logout: () => void;
}

export const InactivityDialog = ({ timeout, logout, ...props }: InactivityViewProps) => {
  const { inactiveTimeRemaining, extendActivity } = useActivity(timeout);

  const timeRemaining = formatDistanceToNowStrict(
    addMilliseconds(new Date(), inactiveTimeRemaining),
  );

  return (
    <AlertDialog.Root {...props}>
      <AlertDialog.Trigger></AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>Are you still there?</AlertDialog.Title>
          <AlertDialog.Description>
            You will be logged out in {timeRemaining} due to
            inactivity.
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel onClick={logout}>Logout</AlertDialog.Cancel>
          <AlertDialog.Action onClick={extendActivity}>Stay logged in</AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
