/* eslint-disable indent */
import { Button } from '../../../../components/ui';
import {
  JsonPropertyTypeString,
  PropertyProps,
  ArrayPropertyProps,
  ObjectPropertyProps,
  StringArrayPropertyProps,
  MapPropertyProps,
} from './types';
import {
  addArraySignature,
  composeNewArrayValue,
  getCardinal,
  isDependenciesSatisfied,
  pushArray,
  expandExpression,
  composeNewMapValue,
  // persistFromProperties,
} from './utils';
import {
  BooleanProperty,
  ComputedProperty,
  DateProperty,
  EnumProperty,
  NumberProperty,
  StringProperty,
  TagInputProperty,
} from './primitives';
import { useContext, useMemo } from 'react';
import { FormBuilderContext } from './context';
import { PropertyTitle } from './component';
import { usePropertyValue } from './hooks';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CounterPartyListModal } from '../views/counterparties';
import { createCounterParty } from '../../actions/counterparties';
import { useUser } from '@/providers/user';
import { useCompany } from '../../providers/company';
import { ClauseSelectionModal } from '../views/ClauseSelectionModal';
import { Clause } from '@/lib/definitions';

const PropertyItems = {
  string: StringProperty,
  number: NumberProperty,
  boolean: BooleanProperty,
  array: ArrayProperty,
  object: ObjectProperty,
  enum: EnumProperty,
  map: MapProperty,
  date: DateProperty,
  computed: ComputedProperty,
} as Record<JsonPropertyTypeString, (props: PropertyProps) => JSX.Element>;

const useErrorToast = (error: any) => {
  useEffect(() => {
    if (error) {
      // Only show toast if error is a string
      if (typeof error === 'string') {
        toast.error(error, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [error]);
};

export function Property(props: PropertyProps) {
  const { property } = props;
  const [lastError, setLastError] = useState(undefined);

  const hasDependenciesBeenSatisfied = useMemo(() => {
    return isDependenciesSatisfied(property.dependencies, props.state);
  }, [property.dependencies, props.state]);

  useErrorToast(lastError);

  // useEffect(() => {
  //   // If the dependencies are not satisfied, we set the value and error to undefined
  //   if (!hasDependenciesBeenSatisfied) {
  //     props.onChange(undefined);
  //     props.setError(undefined);
  //   }
  // }, [hasDependenciesBeenSatisfied])

  if (!hasDependenciesBeenSatisfied) {
    return null;
  }

  const PropertyComponent = PropertyItems[property.type] ?? PropertyItems['string'];
  return (
    <PropertyComponent
      {...props}
      setError={(err) => {
        setLastError(err);
        props.setError(err);
      }}
    />
  );
}

function ArrayProperty(props: ArrayPropertyProps) {
  const context = useContext(FormBuilderContext);
  const [isAddingToLibrary, setIsAddingToLibrary] = useState(false);
  const { user } = useUser('ArrayProperty');
  const { activeCompany: company } = useCompany('ArrayProperty');

  const { property, name, error = [], setError, value: _value, onChange } = props;
  const [lastErrors, setLastErrors] = useState({});

  useErrorToast(typeof error === 'string' ? error : null);

  useEffect(() => {
    Object.entries(lastErrors).forEach(([index, err]) => {
      if (err && typeof err === 'string') {
        // Add type check here
        toast.error(`Item ${parseInt(index) + 1}: ${err}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: `${name}-${index}`,
        });
      }
    });
  }, [lastErrors, name]);

  const offset = useMemo(() => {
    return property.offset ?? 0;
  }, [property.offset]);

  const [value, setValue] = usePropertyValue(
    Array.isArray(_value) ? _value : undefined,
    onChange,
    () => composeNewArrayValue(props.property, context?.extra),
    (value) => addArraySignature(props.property, value),
  );

  if (property.items.type === 'string' && property.items.widget !== 'textarea') {
    return (
      <>
        <TagInputProperty
          {...({
            ...props,
            property: {
              ...property.items,
              title: property.items.title ?? property.title,
            },
          } as StringArrayPropertyProps)}
        />
      </>

    );
  }

  const handleAddToLibrary = async (item: any) => {
    setIsAddingToLibrary(true);
    try {
      const data = {
        orgName: item.rcName || item.fullName,
        counterpartyType: item.isCompany ? 'company' : 'individual',
        address: item.rcAddress || item.address,
        country: item.rcCountry || undefined,
        rcNumber: item.rcNumber || undefined,
        companyType: item.type || undefined,
      };
      await createCounterParty(company, user, data);
      toast.success('Counterparty added to library successfully.');
    } catch (error) {
      toast.error('Failed to add counterparty to library.');
    } finally {
      setIsAddingToLibrary(false);
    }
  };

  // Function to handle adding paragraphs from Clause Library
  const [isClauseModalOpen, setClauseModalOpen] = useState(false);
  const handleParagraphSelection = (
    selected: Array<{ clause: Clause; paragraphIndex: number; paragraphText: string }>
  ) => {
    if (!selected || selected.length === 0) {
      toast.info('No paragraphs selected.');
      setClauseModalOpen(false);
      return;
    }
    const paragraphsToAdd = selected.map((item) => item.paragraphText);
    setValue((prevValue: string[]) => [...prevValue, ...paragraphsToAdd]);
    toast.success('Selected clauses added successfully.');
    setClauseModalOpen(false);
  };


  return (
    <div className='space-y-4'>
      <PropertyTitle property={property} context={props.context} />
      {/* {errorVisible && typeof error === 'string' && <div>{error}</div>} */}
      <div className='space-y-6'>
        {value.length === 0 && (
          <div className='text-muted-foreground'>No items. Click on the add button to add some</div>
        )}
        {value.map((item, index) => (
          <div
            className="flex gap-2"
            key={typeof item === 'object' && `${item.name}.${index}` ? `${item.name}.${index}` : `${item}.${index}`}
          >
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-8 h-8 bg-primary/10 rounded-md">
                {offset + index + 1}
              </div>
            </div>
            <div className="grow border-l-2 border-primary pl-4 space-y-4">
              <Property
                key={`${item}.${index}`}
                name={`${name}.${index}`}
                property={property.items}
                value={item} 
                state={props.state}
                context={props.context}
                error={error?.[index]}
                setError={(err) => {
                  const nextError = { ...error };
                  nextError[index] = err;
                  setLastErrors((prev) => ({
                    ...prev,
                    [index]: err,
                  }));
                  setError(nextError);
                }}
                onChange={(setter) => {
                  setValue((prevValue) => {
                    const nextValue = [...prevValue];
                    nextValue[index] = setter; // Update the value directly
                    return nextValue;
                  });
                  if (error?.[index]) {
                    const nextError = { ...error };
                    delete nextError[index];
                    setError(nextError);
                    setLastErrors((prev) => ({ ...prev, [index]: undefined }));
                  }
                }}
              />
              <div className="flex space-x-2">
                <Button
                  variant='outline'
                  size='sm'
                  className='hover:bg-danger/10'
                  onClick={() =>
                    setValue((prev) => {
                      const next = prev.filter((_, i) => i !== index);
                      if (property.items.type !== 'object') {
                        return next;
                      }
                      // update the cardinal and index of the next items
                      for (let i = index; i < next.length; i++) {
                        next[i] = {
                          ...next[i],
                          __cardinal: getCardinal(offset + i + 1), // update the cardinal!
                          __index: i, // update the index!
                        };
                      }
                      return next;
                    })
                  }
                >
                  Remove
                </Button>
                {index !== 0 && property.items.type === 'object' && property.min === 2 && (
                  <Button
                    loading={isAddingToLibrary}
                    variant='outline'
                    size='sm'
                    onClick={() => handleAddToLibrary(item)}
                  >
                    Add to Library
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='space-x-4'>
        {(property.addLabel !== false ||
          (property.length && value.length < property.length) ||
          (property.max && value.length < property.max) ||
          (property.min && value.length > property.min)) && (
            <>
              <Button
                variant='outline'
                onClick={() => {
                  setValue((prevValue) => {
                    const nextValue = [...prevValue];
                    nextValue.push(pushArray(property, nextValue.length));
                    return nextValue;
                  });
                }}
              >
                {property.addLabel ?? 'Add'}
              </Button>

              {property.items.type === 'object' && property.min === 2 ? (
                <CounterPartyListModal
                  counterParties={context?.counterparties ?? []}
                  onSelect={(selected) => {
                    setValue((prevValue) => {
                      const nextValue = [...prevValue];
                      const isCompany = selected.counterpartyType.toLowerCase() === 'company';

                      const newParty = {
                        ...pushArray(property, nextValue.length),
                        id: selected.id,
                        ...(isCompany
                          ? {
                            rcAddress: selected.address,
                            rcName: selected.orgName,
                            rcCountry: selected.country,
                            type: selected.companyType,
                            rcNumber: selected.rcNumber,
                            isCompany: isCompany,
                          }
                          : {
                            fullName: selected.orgName,
                            address: selected.address,
                            isCompany: isCompany,
                          }),
                      };

                      nextValue.push(newParty);
                      return nextValue;
                    });
                  }}
                >
                  <Button>Add From Library</Button>
                </CounterPartyListModal>
              ) : (
                null
              )}
              {property.items.type === 'string' && (
                <Button
                  variant='outline'
                  onClick={() => setClauseModalOpen(true)}
                >
                  Add From Clause Library
                </Button>
              )}
              {property.items.type === 'string' && (
                <ClauseSelectionModal
                  open={isClauseModalOpen}
                  onOpenChange={setClauseModalOpen}
                  onSelectParagraphs={handleParagraphSelection}
                />
              )}
            </>
          )}
      </div>
      {/* <div>
        {property.addLabel !== false ||
        (property.length && value.length < property.length) ||
        (property.max && value.length < property.max) ||
        (property.min && value.length > property.min) ? (
          <Button
            variant='outline'
            onClick={() => {
              setValue((prevValue) => {
                const nextValue = [...prevValue];
                nextValue.push(pushArray(property, nextValue.length));

                return nextValue;
              });
            }}
          >
            {property.addLabel ?? 'Add'}
          </Button>
        ) : null}
      </div> */}
    </div>
  );
}

function ObjectProperty(props: ObjectPropertyProps) {
  const { property, name, value: _value, error = {}, setError, onChange } = props;
  const [value, setValue] = usePropertyValue(_value, onChange, {});

  return (
    <div>
      <PropertyTitle property={property} context={props.context} />
      <div className='space-y-6'>
        {Object.entries(property.properties).map(([key, prop]) => (
          <Property
            key={key}
            name={`${name}.${key}`}
            property={prop}
            value={value[key]}
            state={value}
            context={props.context}
            error={error?.[key]}
            setError={(err) => {
              const nextError = { ...error };
              nextError[key] = err;
              setError(nextError);
            }}
            onChange={(newValue) => {
              setValue((prevValue) => {
                const nextValue = { ...prevValue };
                if (newValue === undefined) {
                  delete nextValue[key];
                } else {
                  nextValue[key] = newValue;
                }
                return nextValue;
              });
            }}
          />
        ))}
      </div>
    </div>
  );
}

function MapProperty(props: MapPropertyProps) {
  const context = useContext(FormBuilderContext);
  const { property, name, value: _value, error = [], setError, onChange } = props;

  const from = useMemo(() => {
    const result = expandExpression(property.from, context?.state);
    return Array.isArray(result) ? result : [];
  }, [property.from, context?.state]);

  const [value, setValue] = usePropertyValue(
    _value,
    onChange,
    () => {
      const newValue = composeNewMapValue(props.property, from.length, context?.extra);
      return newValue;
    },
    (value) => {
      if (property.items.type !== 'object') return value;
      const fromProperties = Object.entries(property.fromProperties ?? {});
      for (let i = 0; i < from.length; i++) {
        value[i] = value[i] || {};
        for (const [key, fromKey] of fromProperties) {
          value[i][fromKey] = from[i][key];
        }
      }

      return value;
    },
  );

  useEffect(() => {
    if (from.length !== value.length) {
      setValue((prevValue) => {
        let nextValue = [...prevValue];

        if (from.length > value.length) {
          const extra = composeNewMapValue(
            props.property,
            from.length - value.length,
            context?.extra,
          );
          console.log('Adding extra items to value:', extra);
          nextValue = [...nextValue, ...extra];
        } else {
          console.log("Trimming value to match 'from' length");
          nextValue = nextValue.slice(0, from.length);
        }

        // Reapply the `fromProperties` to ensure fields like `definition` are not lost
        const fromProperties = Object.entries(property.fromProperties ?? {});
        for (let i = 0; i < from.length; i++) {
          nextValue[i] = nextValue[i] || {};
          for (const [key, fromKey] of fromProperties) {
            console.log(`Ensuring mapping from '${key}' to '${fromKey}' for index ${i}`);
            nextValue[i][fromKey] = from[i][key];
          }
        }
        return nextValue;
      });
    }
  }, [from, value.length]); // Adding `value.length` as a dependency
  props.state[name] = value;

  return (
    <div>
      <PropertyTitle property={property} context={props.context} />
      <div className='space-y-6'>
        {!value.length && (
          <div className='text-muted-foreground'>No items. Click on the add button to add some</div>
        )}
        {from.map((ob, index) => {
          return (
            <Property
              key={`${name}.${index}`}
              name={`${name}.${index}`}
              property={property.items}
              value={value[index]}
              state={value}
              context={ob}
              error={error[index]}
              setError={(err) => {
                const nextError = { ...error };
                nextError[index] = err;
                setError(nextError);
              }}
              onChange={(newValue) => {
                setValue((prevValue) => {
                  const nextValue = [...prevValue];
                  const fromProperties = Object.entries(property.fromProperties ?? {});
                  if (newValue === undefined) {
                    delete nextValue[index];
                  } else {
                    nextValue[index] = newValue;
                    for (const [key, fromKey] of fromProperties) {
                      nextValue[index][fromKey] = from[index]?.[key];
                    }
                  }
                  return nextValue;
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
